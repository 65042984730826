import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Privacidade</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Privacidade</H5>
                <ScrollAnim.Link
                  to='declaracao-de-privacidade'
                  offsetTop='120'
                >
                  Declaração de Privacidade
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='servicos-de-terceiros'
                  offsetTop='120'
                >
                  Serviços de terceiros
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='safe-harbor'
                  offsetTop='120'
                >
                  Safe Harbor
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='coleta-de-informacao'
                >
                  Coleta de Informação
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='logs-do-servidor-web'
                  offsetTop='120'
                >
                  Logs do Servidor Web
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='informacoes-dos-usuarios'
                  offsetTop='120'
                >
                  Informações dos usuários
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='monitoramento-e-seguranca'
                  offsetTop='120'
                >
                  Monitoramento e segurança
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='informacao-da-hostdime'
                  offsetTop='120'
                >
                  Informação da Hostdime
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='como-hostdime-protege'
                  offsetTop='120'
                >
                  Como Hostdime Protege
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='acesso-do-usuario'
                  offsetTop='120'
                >
                  Acesso do Usuário
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='transferencia-de-usuario'
                  offsetTop='120'
                >
                  Transferência de Usuário
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='modificacao'
                  offsetTop='120'
                >
                  Modificação
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='avaliacao-da-conformidade'
                  offsetTop='120'
                >
                  Avaliação da Conformidade
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='contato-com-a-hostdime'
                  offsetTop='120'
                >
                  Contato com a HostDime
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='contactar-sobre-politica-de-privacidade'
                  offsetTop='120'
                >
                  Contactar sobre Política de Privacidade
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='politica-de-seguranca-da-informacao-e-servicos'
                  offsetTop='120'
                >
                  Política de Segurança da Informação<br /> e Serviços
                </ScrollAnim.Link>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div>
                  <Paragraph>HostDime.com.br - Política de Privacidade (Última Rev. 31/05/2018)</Paragraph>
                  <Paragraph>HostDime.com, Inc. (também referida como HostDime) coleta, utiliza, processa e divulga informações de diferentes maneiras. Esta Política de Privacidade destina-se a fornecer orientações aos usuários finais (clientes, visitantes, parceiros de negócios e clientes de clientes) sobre as formas que a HostDime utiliza esta informação. Nessa Política de Privacidade, esses indivíduos e entidades são referidos coletivamente como “usuários”. Essa Política de Privacidade é parte do contrato da Hostdime com os seus clientes. Se você é um cliente, você deve revisar todos os nossos contratos para entender completamente como esta Política de Privacidade se encaixa nesses termos. Nossos contratos são estabelecidos aqui.</Paragraph>
                </Div>
                <Div id='declaracao-de-privacidade'>
                  <H6 style={{ marginTop: 24 }}>1. Declaração de Privacidade da HostDime</H6>
                  <Paragraph><span>1.1 As seguintes disposições aplicam-se à todos os serviços</span></Paragraph>
                  <Paragraph>A HostDime não fornece para venda, compartilhamento ou concessão qualquer informação disponibilizada a ela por indivíduos ou entidades sujeitas a esta Política de Privacidade, exceto se estiver disposto na mesma. Se você tiver dúvidas sobre como a HostDime utiliza as informações fornecidas a ela, e essa questão não é abrangida por esta Política de Privacidade, por gentileza, entre em contato através do endereço disponibilizado no parágrafo 15 antes de fornecer qualquer informação através de outros canais.</Paragraph>
                  <Paragraph>Os serviços da HostDime são disponibilizados apenas para pessoas acima de dezoito (18) anos. A HostDime não fornece intencionalmente serviços para clientes abaixo dessa idade.</Paragraph>
                </Div>
                <Div id='servicos-de-terceiros'>
                  <H6 style={{ marginTop: 24 }}>2. Serviços de terceiros podem ter políticas de privacidades diferentes das políticas da Hostdime</H6>
                  <Paragraph>Esta Política de Privacidade aplica-se apenas a informações fornecidas à HostDime. Os serviços da HostDime podem incorporar produtos fornecidos por terceiros. Para explanar com mais clareza a incorporação destes produtos aos nossos clientes, as Políticas de Privacidade dos fornecedores dos produtos terceirizados foram incorporados a Política de Privacidade da HostDime. No entanto, se um cliente tem a opção de escolher um produto oferecido por terceiros, ou incorporar o mesmo aos serviços da HostDime por conta própria, as políticas de privacidade, especificamente deste novo produto, serão excluídas das Políticas de Privacidade da HostDime. É de total obrigação do cliente, determinar como as políticas de privacidade de Produtos de Terceiros - e que não são abrangidas pela Política de Privacidade da HostDime - afetam as suas informações e, consequentemente, as informações de seus usuários finais.</Paragraph>
                </Div>
                <Div id='safe-harbor'>
                  <H6 style={{ marginTop: 24 }}>3. A HostDime cumpre as normas de Privacy Shield da Europa, Estados Unidos e Estrutura de proteção de Privacidade da Suíça-EUA</H6>
                  <Paragraph>A HostDime cumpre com o Privacy Shield da Europa, Estados Unidos e Suíça conforme foi definido pelo Departamento de de Comércio dos EUA, resguardando a coleta, uso e retenção de informação pessoal dos cidadãos da União Europeia e Suíça. A HostDime certificou que adere aos princípios do Privacy Shield. Se houver algum conflito entre esta política de privacidade e os princípios descritos no Privacy Shield, este último deve prevalecer. Para ler mais sobre o programa de Privacy Shield e acessar nossa página de certificação, visite <a href='https://www.privacyshield.gov/'>https://www.privacyshield.gov/</a>.</Paragraph>
                  <Paragraph>Em cumprimento às Políticas de Privacy Shield, a HostDime se dispõe prestar conta sobre possíveis dúvidas acerca de sua privacidade, nossa coleta e uso de suas informações pessoais. Cidadãos da União Europeia ou Suíça com reclamações referentes a Privacy Shield devem entrar em contato inicial conosco através do endereço:</Paragraph>
                  <Paragraph><span>HostDime.com, Inc.<br />Attn. Legal Department<br />2603 Challenger Tech Court<br />Suite 140<br />Orlando, FL 32826<br />United States of America</span></Paragraph>
                  <Paragraph>A HostDime também se compromete a resolver reclamações de privacidade não atendidas sob regimento dos Princípios de Proteção de Dados “BBB EU”: um provedor sem fins lucrativos localizado nos Estados Unidos de resolução de disputas alternativas, localizado nos Estados Unidos e operado pelo “Council of Better Business Bureaus”. Se você não receber confirmação de sua reclamação em tempo hábil ou se a sua reclamação não foi satisfatoriamente resolvida, por favor, visite <a href='http://www.bbb.org/EU-privacy-shield/for-eu-consumers/'>http://www.bbb.org/EU-privacy-shield/for-eu-consumers/</a> para mais informações ou registro de queixas.</Paragraph>
                  <Paragraph>Se a sua reclamação não foi resolvida através desses canais, cabe o direito de solicitar a arbitragem vinculativa.</Paragraph>
                </Div>
                <Div id='coleta-de-informacao'>
                  <H6 style={{ marginTop: 24 }}>4. Como a HostDime coleta informação</H6>
                  <Paragraph>Dependendo da utilização dos serviços da HostDime, a HostDime coleta informações de usuários das seguintes formas:</Paragraph>
                  <ul>
                    <li>a. A partir do servidor web da HostDime;</li>
                    <li>b. Usando arquivos temporários de internet, tais como cookies, gifs transparentes, e outras ferramentas similares (ferramentas de identificação);</li>
                    <li>c. Diretamente dos usuários.</li>
                  </ul>
                </Div>
                <Div id='logs-do-servidor-web'>
                  <H6 style={{ marginTop: 24 }}>5. Informações que a HostDime Coleta de Logs do Servidor Web</H6>
                  <Paragraph>Os servidores web da HostDime coletam informações dos visitantes para os sites ligados a esta Política de Privacidade. Devido ao fato dos computadores e navegadores de cada usuário serem diferentes, durante a visita de um usuário, a HostDime pode coletar algumas ou todas as seguintes informações:</Paragraph>
                  <ul>
                    <li>a. Endereço de protocolo de Internet;</li>
                    <li>b. O tipo de navegador ou computador usado;</li>
                    <li>c. O número de links clicados no site oficial da HostDime;</li>
                    <li>d. O estado ou país a partir do qual o nosso site foi acessado;</li>
                    <li>e. A data e hora da visita;</li>
                    <li>f. O nome do provedor de serviços de internet usado para acessar o site da HostDime;</li>
                    <li>g. A página web visitada antes de vir para o site da HostDime;</li>
                    <li>h. As páginas visitadas no site da HostDime.</li>
                  </ul>
                </Div>
                <Div id='informacoes-dos-usuarios'>
                  <H6 style={{ marginTop: 24 }}>6. Informações coletadas pela HostDime diretamente dos usuários</H6>
                  <Paragraph>a. Informação de Registro</Paragraph>
                  <Paragraph>Para adquirir serviços com a HostDime, os usuários devem fornecer informações detalhadas, incluindo informações de contato como nomes, e-mails, endereços e telefones. Durante o processo de compra, os usuários podem fornecer informações opcionais para HostDime, como endereços de e-mails secundários e detalhes através de mensagem instantânea. Se essa informação é fornecida, é coberto por esta Política de Privacidade desde que seja fornecida voluntariamente. Esta informação é um subconjunto de ferramentas de identificação, e é referido individualmente como "Informações de Registro".</Paragraph>
                  <Paragraph>b. Ferramentas de identificação</Paragraph>
                  <Paragraph>A HostDime pode usar ferramentas de identificação automatizados como cookies e Google Analytics. Cada uma dessas ferramentas de identificação funcionam de forma diferente, no entanto, todos eles coletam informações como: Site que acessou antes do nosso site, sistema operacional do usuário, tipo de navegador, páginas visitadas no site da HostDime e período de tempo visitando o site da HostDime. Além disso, ferramentas de identificação pode manter os usuários registrados no sistema da HostDime. De tempos em tempos, a HostDime pode usar ferramentas de identificação que serão utilizados com fins publicitários e outras informações para os usuários depois que visitam o site da HostDime.</Paragraph>
                  <Paragraph>c. Informações de Suporte</Paragraph>
                  <Paragraph>Quando os usuários entram em contato com a HostDime pedindo suporte para seus serviços, algumas informações poderão ser solicitadas para confirmar alguns dados com os registrados em nosso sistema. Esta informação será utilizada para identificar os clientes autorizados.</Paragraph>
                  <Paragraph>d. FAQ e Informações</Paragraph>
                  <Paragraph>A HostDime oferece aos usuários a capacidade de trocar informações sobre os seus serviços e as suas experiências com estes serviços (<Link to='/suporte'>Ajuda</Link> e <Link to='/blog'>Blog</Link>). Ambos são publicamente acessíveis e podem ser indexados pelos motores de busca e outras ferramentas. Os usuários não são obrigados a divulgar informação que irá identificá-los pessoalmente, a fim de participar nestas ferramentas. Todas as Informações do <Link to='/suporte'>Ajuda</Link> e <Link to='/blog'>Blog</Link> podem ser acessados e utilizados por terceiros.</Paragraph>
                </Div>
                <Div id='monitoramento-e-seguranca'>
                  <H6 style={{ marginTop: 24 }}>7. Monitoramento e segurança</H6>
                  <Paragraph>A HostDime leva a segurança de sua rede muito a sério. Para garantir a segurança, integridade dos dados e privacidade, a HostDime usa programas que monitoram o tráfego de rede e o acesso à rede. Estes programas coletam informações a respeito do uso do usuário referente aos serviços, site da HostDime e rede (Informações de Tráfego).</Paragraph>
                </Div>
                <Div id='informacao-da-hostdime'>
                  <H6 style={{ marginTop: 24 }}>8. Como HostDime Usa informações abrangidas por esta Política de Privacidade</H6>
                  <Paragraph>a. Geralmente</Paragraph>
                  <ul>
                    <li>i. A HostDime é obrigada a cumprir com as leis, regulamentos e ordens judiciais. As informações abrangidas por esta Política de Privacidade podem ser divulgadas, se a HostDime determina, a seu exclusivo critério, que tal divulgação é necessária ou obrigatória. A HostDime também pode divulgar esta informação se, a seu exclusivo critério, tal divulgação é necessária ou requerida para proteger os seus interesses ou os interesses dos seus clientes.</li>
                    <li>ii. A HostDime poderá divulgar informações cobertas por esta Política de Privacidade em conexão com a venda de seu negócio.</li>
                    <li>iii. A HostDime pode agregar informações abrangidas por esta Política de Privacidade e divulgá-la fora do seu negócio. Esta informação não identifica usuários individualmente.</li>
                    <li>iv. A HostDime poderá utilizar as informações cobertas por esta Política de Privacidade para contatá-lo diretamente para responder perguntas, prestar os seus serviços a você, gerenciar sua conta ou para informá-lo sobre anúncios.</li>
                    <li>v .A HostDime poderá utilizar as informações cobertas por esta Política de Privacidade para o informar sobre novos produtos e serviços. Na informação sobre novos produtos e serviços, podemos incluir produtos de terceiros, porém suas informações não poderão ser fornecidas a terceiros. Neste uso de informações do usuário, os terceiros só receberão informações agregadas. No entanto, se um usuário responder a um anúncio de marketing, terceiros estarão cientes sobre o usuário individualmente. Os usuários podem optar por não receber essas informações, clicando em "unsubscribe" ou "cancelar recebimento" em qualquer material de marketing, ou cancelar a inscrição através da área de cliente CORE.</li>
                  </ul>
                  <Paragraph>b. Informação Específica</Paragraph>
                  <ul>
                    <li><span>i. Logs Servidor Web (descritos no parágrafo 5)</span><br />
                    As informações coletadas a partir de logs do servidor Web não são divulgadas pela HostDime. Veja quais informações no parágrafo 5 desta Política de Privacidade.</li>
                    <li><span>ii. Informações de Registro (descrito no parágrafo 6)</span><br />
                    A HostDime utilizará Informações de Registro em todo o seu negócio e vai passar Informações de Registro a terceiros para processamento e uso conforme necessário para prestar serviços à HostDime e aos usuários.</li>
                    <li><span>iii. Ferramentas de identificação (descritas no parágrafo 6)</span><br />
                    As informações coletadas usando ferramentas de identificação, exceto Informações de Registro, pode identificar usuários individualmente. Esta informação será usada para fornecer informações de marketing da HostDime aos usuários. Os terceiros podem usar esta informação de forma agregada; no entanto, eles estão contratualmente proibidos de usar essas informações para identificar usuários individualmente. A HostDime usa cookies para salvar as preferências do usuário e informações de login e fornece funcionalidade personalizada.</li>
                    <li><span>iv. Informações de suporte (descritas no parágrafo 6)</span><br />
                    Informações de suporte pode identificar usuários individualmente. Além de fornecer suporte aos usuários, a HostDime poderá divulgar informações de suporte para os fornecedores de terceiros, a fim de prestar o apoio solicitado pelo usuário. Esses terceiros podem divulgar e utilizar informações de suporte para melhorar seus processos de negócios e de marketing. A menos que necessário para fornecer o devido suporte aos usuários, a HostDime não divulgará informações que possam identificar o usuário individualmente a estes terceiros. Durante um pedido de suporte, os usuários podem solicitar que a HostDime não divulgue Informações de suporte a terceiros. Este pedido impedirá a HostDime de fornecer suporte ao usuário.</li>
                    <li><span>v. Informações de Tráfego (descritas no parágrafo 7)</span><br />
                    Informações de Tráfego podem identificar usuários individualmente. A HostDime só vai usar informações de tráfego para garantir a integridade da sua rede, e mais nenhuma outra finalidade. As Informações de tráfego podem ser divulgadas pela HostDime a fim de cumprir as suas obrigações legais e à aplicação da lei quando for obrigada a fazê-lo.</li>
                  </ul>
                </Div>
                <Div id='como-hostdime-protege'>
                  <H6 style={{ marginTop: 24 }}>9. Como a HostDime Protege sua Informação</H6>
                  <Paragraph>A HostDime utiliza técnicas de segurança para evitar perda, mau uso, alteração ou divulgação não autorizada de informações sob nosso controle. Usamos medidas de segurança, incluindo mas não limitado a: firewalls, sistemas que utilizam rede segura (não pública), senhas complexas, antivírus, mitigação e software de detecção. Quando a HostDime pede que os usuários forneçam informações financeiras (como número de cartão de crédito) os dados são protegidos usando a tecnologia Secure Sockets Layer (SSL). A comunicação eletrônica (por exemplo: e-mail, chat online, ou mensagens instantâneas) que os usuários podem enviar para a HostDime podem não ser 100% seguros, a menos que a HostDime informe com antecedência de que as medidas de segurança estarão em vigor antes da transmissão das informações por estes meios. Os usuários não devem enviar informações confidenciais, tais como informações financeiras, números de segurança social ou senhas para a HostDime através de comunicação electrónica não segura.</Paragraph>
                </Div>
                <Div id='acesso-do-usuario'>
                  <H6 style={{ marginTop: 24 }}>10. Como os usuários podem acessar e corrigir informações cobertas por esta Política de Privacidade</H6>
                  <Paragraph>Se um usuário deseja acessar e / ou atualizar as informações coletadas pela HostDime, o usuário pode acessar o site da HostDime ou portal do cliente (CORE). Se um usuário gostaria de ter uma lista de sistemas que contém informações sobre sua conta, isso também pode ser obtido entrando em contato com nossa equipe Suporte Financeiro. As informações abrangidas por esta política de privacidade pode ser excluído a pedido de um usuário. No entanto, nos reservamos o direito de reter a informação necessária para proteger nossos interesses. A menos que estabelecido aqui, as informações abrangidas por esta política de privacidade será retido pela HostDime após a cessação do acordo de usuário.</Paragraph>
                </Div>
                <Div id='transferencia-de-usuario'>
                  <H6 style={{ marginTop: 24 }}>11. Os usuários podem transferir informações cobertas por esta Política de Privacidade a Terceiros</H6>
                  <Paragraph>Os usuários podem transferir suas contas para terceiros através da apresentação de um pedido através de nosso help-desk. A HostDime responderá com um formulário de transferência. Uma vez que o formulário é respondido, o mesmo é verificado, e sendo autorizado a conta será transferida. O novo proprietário ficará obrigado por esta Política de Privacidade e os acordos estabelecidos no parágrafo 2.</Paragraph>
                </Div>
                <Div id='modificacao'>
                  <H6 style={{ marginTop: 24 }}>12. Responsabilidade</H6>
                  <Paragraph>A Hostdime pode ser potencialmente responsável em casos de transferência de dados pessoais de cidadãos da Europa e/ou Suíça para terceiros recebidos nos termos da Privacy Policy da União Europeia/EUA e/ou Suíça/EUA,</Paragraph>
                </Div>
                <Div id='avaliacao-de-conformidade'>
                  <H6 style={{ marginTop: 24 }}>13. Como e quando esta Política de Privacidade é modificada</H6>
                  <Paragraph>A HostDime poderá modificar esta política de privacidade a qualquer momento. Os usuários serão notificados sobre qualquer alteração, que não seja por impresso, através de um aviso na primeira página do site da HostDime ou através de um aviso no painel de controle do usuário. As modificações estarão em vigor assim que houver a notificação.</Paragraph>
                </Div>
                <Div id='contato-com-a-hostdime'>
                  <H6 style={{ marginTop: 24 }}>14. Como HostDime Avalia a sua conformidade com a política de privacidade</H6>
                  <Paragraph>A HostDime usa uma abordagem de auto-avaliação para assegurar a conformidade com esta Política de Privacidade e regularmente verifica que a política de privacidade é precisa, exibida, acessível e dentro das diretrizes do Safe Harbor princípios de privacidade, tanto para a EU e a Suíça.</Paragraph>
                </Div>
                <Div id='contactar-sobre-politica-de-privacidade'>
                  <H6 style={{ marginTop: 24 }}>15. Como contactar a HostDime sobre esta Política de Privacidade</H6>
                  <Paragraph>Se os usuários tiverem quaisquer perguntas, comentários ou sugestões sobre esta Política de Privacidade ou o uso e divulgação de informações pessoais, entre em contato pelo e-mail HostDime <a href='mailto:core.desk@hostdime.com.br'>core.desk@hostdime.com.br</a> que a HostDime responderá em tempo hábil. Se um usuário não for capaz de resolver a sua reclamação ou disputa interna, a HostDime trabalhará com o Better Business Bureau (BBB), um serviço de resolução de disputas de terceiros.</Paragraph>
                  <Paragraph>Pode também entrar em contato conosco por escrito no endereço:</Paragraph>
                  <Paragraph>HostDime.com, Inc.<br />2603 Challenger Tech Court<br />Suite 140<br />Orlando, FL 32826</Paragraph>
                </Div>
                <Div id='politica-de-seguranca-da-informacao-e-servicos'>
                  <H6 style={{ marginTop: 24 }}>16. Política de Segurança da Informação e Serviços</H6>
                  <Paragraph>1. Propósito</Paragraph>
                  <ul>
                    <li>&#9679; Criar uma cultura de conscientização e proteção de informações confidenciais e restritas em nossas operações, sistemas de informação e atividades de trabalho diárias.</li>
                    <li>&#9679; Estabelecer um compromisso sob o qual todas as pessoas que trabalham para e com a HostDime Brasil, entendem quais informações precisam ser protegidas e como manter sua Confidencialidade, Integridade e Disponibilidade.</li>
                    <li>&#9679; Descrever procedimentos autorizados para lidar com informações confidenciais e restritas, usando controles administrativos, físicos e técnicos apropriados.</li>
                    <li>&#9679; Descrever as consequências das decisões e ações que não estão em conformidade com esta política.</li>
                    <li>&#9679; Definir a aplicação da avaliação de riscos para identificar e tratar os riscos à segurança da informação.</li>
                    <li>&#9679; Garantir o atendimento adequado às regulamentações vigentes aplicadas ao negócio.</li>
                    <li>&#9679; Desenvolver relações de confiança com clientes e outros, com base na conformidade com leis e regulamentos aplicáveis, assim como nos requisitos contratuais.</li>
                    <li>&#9679; Fazer cumprir as metas destinadas aos objetivos de segurança da informação e serviços, definidos pela organização.</li>
                    <li>&#9679; Estabelecer um compromisso com a melhoria contínua do sistema de gestão integrado e dos serviços.</li>
                  </ul>
                  <Paragraph>2. Aplicação</Paragraph>
                  <Paragraph>Esta política, com seus requisitos e responsabilidades, aplica-se a todos os indivíduos que processam informações confidenciais e restritas para e / ou em nome da HostDime Brasil, incluindo a coleta, uso, armazenamento, compartilhamento e gerenciamento de informações confidenciais e restritas.</Paragraph>
                  <Paragraph>3. Controles</Paragraph>
                  <Paragraph>A responsabilidade pela implantação e manutenção de controles relativos a proteção de informações confidenciais e restritas, é compartilhada com todos os funcionários que trabalham direta ou indiretamente para HostDime Brasil. Usamos controles e salvaguardas, no que se refere avaliação de riscos, para proteger informações confidenciais e restritas de coleta, uso, divulgação e transmissão não autorizadas, corrupção ou perda.</Paragraph>
                  <Paragraph>4. Responsabilidades</Paragraph>
                  <Paragraph>Demais informações acerca desta Política de Segurança da Informação e Serviços, devem ser solicitadas através do email <a href='certificacoes@hostdime.com.br'>certificacoes@hostdime.com.br</a>, e que será apresentada por um representante da HostDime Brasil.</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Privacidade' />
      <PageTemplate
        title='Política de Privacidade'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
